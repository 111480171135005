.aligntop {
    margin-top: -10px !important;
}

.startleft {
    margin-left: -7px !important;
}

.fprops {
    width: 260px !important;
}

.fpropsnew {
    width: 100px !important;
}

.cursor-not-allowed {
    cursor: not-allowed;
    pointer-events: none;
}

.bgcolor {
    background: "rgb(227 245 255)";
}

.cardbgcolor {
    background: "#f4f4f4";
    box-shadow: "rgb(207 202 202) 0px 0px 10px"
}

.wizard {
    /* height: 250px!important; */
    /* max-width: 1350px!important; */
}

.recommendelist {
    margin-left: -30px !important;
}

body {
    background: #EEEEEE;
    background: #e3f5ff !important;
    height: 88% !important;
    font-family: roboto,-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

.ant-card-head-title {
    text-align: center;
}

@media print {
    body * {
        visibility: hidden;
    }

    #report,
    #report * {
        visibility: visible;
        width: 750px;
    }

    #report {

        position: absolute;
        left: 0;
        top: 0;
    }
}


/* .ant-steps-item-icon{
    
    border-color:green!important;
   
} */



/* .anticon svg
{
    color:green;
    
} */

/***********************************************************/


/* .ant-rate-star-full .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
    color: #a9a9a9;
} */
/*
.ant-rate-star-first:hover,
.ant-rate-star-second:hover {
    color: #ffe924 !important;
} */
.ant-rate {
    background:#909090;
    padding: 0px 10px 5px !important;
    border-radius: 3px;
}
 
.text-50 {
    width: 48.5% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
} 
  input[type=number] {
  -moz-appearance: textfield !important;
  
} 

.inclusions_block .ant-space { display:block;}
.ant-table-measure-row { display:none !important;}
td.ant-table-cell p { margin-bottom: 0px;}
.prop_upload_error.prop_upload_scrollbar .ant-upload-list.ant-upload-list-picture-card { overflow:auto;}
.m-red  
{
    color: #ff0000;
}
/* .ifsc:valid,.uppercasetext { text-transform: uppercase; } */
.date_in .ant-select-arrow,.date_out .ant-select-arrow { top: 51%; }
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector { padding: 0 10px 0 5px !important; }
.publish_blk .ant-descriptions-bordered .ant-descriptions-item-label,.publish_blk .ant-table-thead > tr > th {
    background-color: #ffffff;
    border: 1px solid #ddd !important;
    padding: 16px 5px;
}
.publish_table .ant-table-tbody .ant-table-cell { border:1px solid #ddd;}
.ant-table-thead > tr > th{ padding: 16px 5px !important; }
.ant-picker-time-panel-column::after {
    display: block;
    height: 0px!important;
    content: '';
}
.channel-manager .ant-card-body {
    width: 100%;
}
.publish_tab .ant-table { background:none;}
.channel-manager .ant-radio-group {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
}

.channel-manager .ant-card {
    width: 75%;
}

nav.row {
    --bs-gutter-x: 0rem !important;
}

.g-padding .ant-card-body {
    padding: 12px !important;
}

.channel-manager .ant-card-body {
    display: flex;
}

.channel-manager.ant-card.ant-card-bordered {
    min-height: 57px;
}

.inclusions .ant-tag-checkable {
    min-width: 90px;
}

.photos .ant-upload-list-picture-card-container {
    width: 31% !important;
    margin: 1% !important;
}
.ant-upload-list,.d_v_c { display:flex; align-items: center; }
.ant-upload { margin-bottom: 0px !important; }
.photos .ant-card-body,
.location .ant-card-body,
.facilities .ant-card-body,
.roomdet .ant-card-body,
.profile .ant-card-body {
    padding: 12px !important;
}
.ant-picker-disabled { background: #dedede !important; color:#000 !important}
.ant-upload-span { max-height:134px; }
.ant-upload-list-picture-card-container { margin:0px !important; }

.publish_column .ant-descriptions-item-content {
    padding: 10px 0px !important;
}
/* input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
} */
.maplatlong_map { max-height:300px;}
.publish_full-width .ant-descriptions-item-container:first-child {}
.ant-select-arrow,.ant-picker-suffix { color:#000 !important;}
.ant-steps-item-finish .ant-steps-item-icon { background:#02cc02 !important;}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon { color:#fff !important;     top: -4.5px;}

.publish .ant-descriptions-item-container .ant-descriptions-item-content {
    display: block !important;
}

.publish .publish_full-width .ant-descriptions-item-label {
    width: 100% !important;
}
.ant-steps-vertical > .ant-steps-item.ant-steps-item-finish .ant-steps-item-content { font-weight: bold; }
#chkuploadform .ant-upload-picture-card-wrapper,
#chkuploadform .ant-upload.ant-upload-select-picture-card,
.ant-upload-list-picture-card-container {
    width: 100% !important;
}
.gsttan .ant-table-tbody tr td  { padding:10px 5px 10px 5px; }
.gsttan .ant-table-tbody tr td p { margin-bottom:0px !important;}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0px !important;
}
.kindly_note .ant-descriptions-item-label { font-weight:bold;}
.padd-5,.ant-table-tbody>tr>td { padding: 16px 5px !important; }
.verified_but {     
    border: 1px solid rgb(2, 204, 2);
    padding: 5px;
    display: inline-flex;
    border-radius: 2px; } 
    .verified_but .anticon.anticon-check { display:flex; align-items: center; }
.channel-manager .ant-radio-wrapper {
    margin-right: 0px !important;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img { padding: 0px 24px !important; }
.prop_upload_error .ant-upload-list-picture-card .ant-upload-list-item-error { /* border: 1px solid #d9d9d9 !important; */ }
.prop_upload_error .ant-tooltip { display:none !important; }
.no-border-l .ant-card-bordered {
    border: none !important;
    background: #fff !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.col-md-10.bgcolor h5 {
    margin-top: 15px !important;
}

.inclusions .ant-tag-checkable-checked {
    font-weight: bold;
}

.profile input {
    margin-top: 0px !important;
    margin-bottom: 0.5rem !important;
}

.no-border-l {
    background: #fff !important;
}

.publish .ant-descriptions-bordered .ant-descriptions-item-label,
.publish .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 10px 10px;
}
.otpverificationscreen { 
    top: 0px;   
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
}
.otppopup{    width: 300px;
    height: 200px;
    background: #fff;
    border-radius: 10px;
}
.asn-center .ant-card-body {
    display: flex;
    align-items: center;
}
.verify_msg .ant-modal-header { background: #1890ff !important; }
.ant-modal-header {
    background-color: #ff5959 !important;
    border-radius: 5px 5px 0px 0px;
}

.error_msg .ant-modal-header {
    background-color: #ff5959 !important;
    border-radius: 5px 5px 0px 0px;
}
.error_msg .ant-modal-close-x,.success_msg .ant-modal-close-x { display:none !important}
.ant-modal-footer .ant-btn-primary { padding: 3px 24px;
    border-radius: 25px;} 
.ant-modal-content .ant-modal-close { top: 7px;
    right: 9px; }
    .ant-modal-content .ant-modal-close-x {     width: 40px;
        height: 40px;     border-radius: 25px;}
.ant-modal-title {
    color: #fff !important;
}
.success_msg .ant-modal-header {
    background-color: #09c202 !important;
    border-radius: 5px 5px 0px 0px;
}
.success_msg .ant-modal-close-x,.info_msg .ant-modal-close-x { background: #fff !important; }
.success_msg .anticon.anticon-close.ant-modal-close-icon,.info_msg .anticon.anticon-close.ant-modal-close-icon { color:#ff0000 !important;}
.success_msg .ant-modal-close-x:hover,.info_msg .ant-modal-close-x:hover { border-bottom: 0px !important;}
.ant-modal-content {
    border-radius: 5px !important;
}
.d_f_a_c { display: flex;
    align-items: center;}
.logo_name { width:100%; margin-top:20px; margin-bottom: 30px; }
.ant-modal-close-x {
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    /* border-left: 1px solid #ddd; */
    background: #fa9393;
    border-radius: 0px 5px 0px 0px;
}

.ant-modal-close-x:hover {
    background: #ffffff !important;
    color: #ff0000 !important;
    border-bottom: 1px solid #ff0000;
}

/* .border_red {
    border: 1px solid #ff0000 !important;
} */

input:read-only {
    background-color: #dedede;
    pointer-events: none !important;
}

.loaderloading {
    position: fixed;
    height: 100%;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
}
.prop_upload > div > div >span > div { padding: 0.5rem!important;}
.ant-select-item:hover { background:#1890ff !important; color:#fff !important;}
.loaderloading span {
    /*background: #fff;
     padding: 20px 40px; */
    border-radius: 5px;
    /* box-shadow: 0px 0px 10px #646464; */
}
textarea#pro_descrip { min-height: 108px; }
.c_leaf .ant-upload.ant-upload-select.ant-upload-select-picture-card { margin-right:0px !important; }
.ant-rate-star .ant-rate-star-zero .ant-rate-star-first, .ant-rate-star-second { color: #bdbdbd; }
.ant-picker-header button {color: rgba(0, 0, 0, 0.8) !important; }
.l_city .ant-select-single:not(.ant-select-customize-input) .ant-select-selector { padding-left:11px !important; }
.photos .ant-upload.ant-upload-select-picture-card {
    width: 185px;
    height: 185px;
}
.photos .ant-upload-span {
    max-height: 185px;
}
.photos .ant-upload-list-picture-card-container {
    height: 185px;
}
.profile-blk .ant-upload-list-picture-card-container {
    height: 104px;
}
.example {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 20px 40px;
    text-align: center;
    background: rgba(255,255,255,1);
    border-radius: 4px;
  }
  .example .ant-spin-dot {width: auto; 
    height: auto; 
    top: -6px;
    left: -12px;}
    .example .ant-spin-dot-item {    width: 15px;
        height: 15px; }
        .example .ant-spin.ant-spin-spinning { padding-left: 19px; }
/* .ant-rate {color: #fadb14 !important;} */

@media only screen and (min-width: 576px) {
 
    .f-height, 
    .f-height>div,
    .f-height>div>div {
        height: 100% !important;
        width: 100% !important;
    }

    .f-height-o {
        height: 100% !important;
    }

    .roomdet-add {
        margin-left: 0px;
        text-align: left !important;
        padding-left: 0px;
    }
 .d_f_a_c .ant-radio-group { min-width: 128px; }
 .direction_w-100 { width:100%; display: block;}
}

@media only screen and (min-width: 768px) {
    .side-menu .ant-card-body {
        padding: 12px !important;
        position: fixed;
    }
    @-moz-document url-prefix() {
        .side-menu .ant-card-body {
            width:17%;
        }
      }

    .ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
        float: left;
        margin-right: 5px;
    }

    nav.row {
        position: fixed;
        width: 100%;
        height: 85px;
        top: 0px;
        z-index: 100;
        background: #fff;
        box-shadow: 0px 0px 10px #ddd;
    }

    .container-fluid {
        margin-top: 85px;
    }
    .ant-modal { width:600px !important; }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) { background-color:#1890ff!important; } 
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) .ant-select-item-option-content { color:#fff !important;}
    .ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
        margin-right: 5px !important;
    }

    .h100 {
        height: 100vh;
        position: fixed;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .layout-ml {
        margin-left: 16.666667% !important;
    }
    
    .mobile-show { display:none !important;  }
    .logo-display { display:block !important; width: 100%; margin-right:0px !important; border-top: 1px solid #ddd;  }
    .side-menu .mt-md-5 { margin-top: 1rem!important; }
    .ant-steps-item-title { font-weight: bold; }
    .date_in > div,.date_out > div { width:50%; }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 0px 0 3px !important;
    }
    .a_type.ant-select-single:not(.ant-select-customize-input) .ant-select-selector { padding: 0 0px 0 9px !important;}
    .ant-select-arrow { right:4px !important;}
}

@media only screen and (min-width: 1000px) {
    .ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
        margin-right: 16px !important;
    }
    .ant-steps-small .ant-steps-item-title {
        font-size:16px !important;
    }
}

@media only screen and (min-width: 1200px) {
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 0px 0 12px !important;
}
}

@media only screen and (max-width: 767px) {

    .mobile-hide .ant-steps-item,
    .mobile-hide .ant-steps-item.ant-steps-item-active .ant-steps-item-tail {
        display: none !important;
    }

    .mobile-hide .ant-steps-item.ant-steps-item-active {
        display: block !important;
    }

    .ant-steps-small .ant-steps-item-icon {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 18px;
    }

    .ant-steps-vertical>.ant-steps-item .ant-steps-item-content {
        min-height: auto !important;
    }

    .mobile-hide .ant-steps-item.ant-steps-item-active .ant-steps-item-icon {
        width: 35px !important;
        height: 35px !important;
        font-size: 18px;
    }

    .mobile-hide .ant-steps-item.ant-steps-item-active .ant-steps-item-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }

    .photos .ant-upload-list-picture-card-container {
        width: 49% !important;
        margin: 0.5% !important;
    }

    .photos .ant-upload-list-picture-card .ant-upload-list-item {
        padding: 0px;
    }

    .photos .ant-card-body {
        padding: 12px !important;
    }

    .publish_column .ant-descriptions-item-container .ant-descriptions-item-content {
        display: table-cell !important;
    }

    label,
    #chkuploadform {
        margin-top: 10px;
    }

    .publish .ant-table-thead>tr>th {
        padding: 15px 5px !important;
    } 
    .logo-display { display:none !important;  }
    
    .date_in > div, .date_out > div { width:50%; }
    .mt-sm-3.desk_browser {display:none !important;}
    .desktop_show { display:none !important;}
}

/*********************responsive *************************/
@media only screen and (max-width: 700px) {
    .text-50 {
        width: 100% !important;
    }

    .channel-manager .ant-radio-group {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .channel-manager .ant-card,
    .channel-manager .w-75 {
        width: 100% !important;
    }

    .channel-manager .ant-card-body {
        display: block !important;
    }

    .channel-manager .ant-card-body>div {
        margin-top: 10px;
    }

    .publish .ant-descriptions-item-label {
        width: 100% !important;
    }

    .publish .kindly_note .ant-descriptions-item-label {
        width: auto !important;
    }

    .publish_column .ant-descriptions-item-content {
        display: table-cell !important;
    }

    .publish_column .ant-descriptions-item-label {
        display: table-cell;
    }

    .Tariff_block {
        width: 600px;
        overflow: hidden;
    }

    .Tariff {
        min-width: 100% !important;
    }

    .Tariff .ant-descriptions-view {
        overflow: auto !important;
    }
}

@media only screen and (max-width: 575px) {

    .f-height,
    .f-height>div,
    .f-height>div>div {
        height: 100% !important;
        width: 100% !important;
    }

    .d-m-none {
        display: none !important;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-right: 10px !important;
    }
    .ant-upload-list, .d_v_c { display:block !important;} 
    .photos .ant-upload-list-picture-card-container { width: 100%!important; } 
}


/* miui autocomplete */
.l_city .css-viou3o-MuiAutocomplete-root { width:auto !important; }
.l_city .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root { background:#fff !important; padding: 0px 34px 0px 0px !important;}
.l_city .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {border-top: 1px solid #ddd !important;}
.l_city .css-1d3z3hw-MuiOutlinedInput-notchedOutline { border: 1px solid #ddd !important; top: -6px !important; }
.l_city  .css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 4.5px 4px 5.5px 10px !important;
}

.verify_msg .ant-modal-body { padding-top:5px }

.propid {pointer-events: none; cursor: not-allowed;}
.discounthelptext { color:blue; text-decoration:underline; }
.discounthelptext:hover { cursor:pointer; }